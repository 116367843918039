import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, ModalBody } from 'reactstrap'

import { expertBlockQuoteMixin } from '../elements'

const ArticleAboutModalButton = styled.span`
  position: relative;
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 20px;
  font-size: 20px;
  font-weight: 700;
  padding-right: 25px;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    &:before {
      @media (min-width: 1200px) {
        right: 8px;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    right: 10px;
    bottom: 6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-left: 6px solid ${(props) => props.theme.colorGreenLight};
    border-bottom: 6px solid transparent;
    transition: all 0.2s ease;
    @media (max-width: 1199px) {
      transform: rotate(90deg);
    }
  }
`

const ArticleAboutModalWrapper = styled(Modal)`
  .modal-content {
    background-color: transparent;
    border: none;
  }
`

const ArticleAboutModalBody = styled(ModalBody)`
  ${expertBlockQuoteMixin}
  position: relative;
  bottom: auto;
  display: flex !important;
  margin-left: auto !important;
  margin-right: auto !important;
`

const ArticleAboutModal = (props) => {
  const { children } = props
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  return (
    <>
      <ArticleAboutModalButton onClick={() => toggle()}>
        o mnie
      </ArticleAboutModalButton>
      <ArticleAboutModalWrapper
        isOpen={modal}
        toggle={toggle}
        fade={false}
        size="lg"
        centered
      >
        <ArticleAboutModalBody>
          <span className="close" onClick={() => toggle()} /> {children}
        </ArticleAboutModalBody>
      </ArticleAboutModalWrapper>
    </>
  )
}

export default ArticleAboutModal
