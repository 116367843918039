import React from 'react'
import Img from 'gatsby-image'
import { Link, withPrefix } from 'gatsby'
import styled from 'styled-components'
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap'

const UsefulToolsWrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 150px;
  @media (max-width: 575px) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
`

const UsefulToolsTitle = styled.h2`
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 60px;
`

const UsefulToolsCardWrapper = styled(Col)`
  margin-bottom: 20px;
  &.withLink {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 100px;
    @media (max-width: 991px) {
      padding-top: 30px;
      padding-bottom: 0;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      padding-top: 0;
      padding-bottom: 100px;
    }
  }
`

const UsefulToolsImgWrapper = styled.div``

const UsefulToolsCardLinkWrapper = styled.a`
  display: block;
  height: 100%;
  cursor: pointer;
  text-decoration: none !important;
`

const UsefulToolsCard = styled(Card)`
  height: 100%;
  line-height: 1;
  border: none;
`

const UsefulToolsCardTitle = styled(CardTitle)`
  color: ${(props) => props.theme.colorBlack};
  font-size: 24px;
  margin-bottom: 10px;
`

const UsefulToolsCardText = styled(CardText)`
  color: #000;
  font-size: 16px;
  font-weight: 300;
`

const UsefulToolsCardLinkButtonWrapper = styled.div``

const UsefulToolsCardLinkButton = styled(Link)`
  position: relative;
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  padding-right: 25px;
  transition: all 0.2s ease;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorGreenLight};
    text-decoration: none;
    &:before {
      right: 5px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    right: 10px;
    bottom: 6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-left: 6px solid ${(props) => props.theme.colorGreenLight};
    border-bottom: 6px solid transparent;
    transition: all 0.2s ease;
  }
`

const UsefulTools = ({ items }) => {
  return (
    <UsefulToolsWrapper id="usefulTools">
      <Container>
        <Row>
          <Col xs={12}>
            <UsefulToolsTitle>Przydatne narzędzia</UsefulToolsTitle>
          </Col>
        </Row>
        <Row>
          {items.map((item) => (
            <UsefulToolsCardWrapper xs={12} sm={6} md={4} lg={3}>
              <UsefulToolsCardLinkWrapper
                href={withPrefix(item.link)}
                target="_blank"
                onClick={item.onClick}
              >
                <UsefulToolsCard>
                  <UsefulToolsImgWrapper className="card-img-top">
                    <Img
                      fluid={item.image.childImageSharp.fluid}
                      className="usefulToolImage"
                      alt={item.title}
                    />
                  </UsefulToolsImgWrapper>
                  <CardBody>
                    <UsefulToolsCardTitle tag="h3">
                      {item.title}
                    </UsefulToolsCardTitle>
                    <UsefulToolsCardText>{item.subtitle}</UsefulToolsCardText>
                  </CardBody>
                </UsefulToolsCard>
              </UsefulToolsCardLinkWrapper>
            </UsefulToolsCardWrapper>
          ))}

          <UsefulToolsCardWrapper xs={12} sm={6} md={3} className="withLink">
            <UsefulToolsCardLinkButtonWrapper>
              <UsefulToolsCardLinkButton
                to="/strefa-rodzica/baza-wiedzy#narzedzia"
                title="zobacz inne narzędzia"
              >
                zobacz inne narzędzia
              </UsefulToolsCardLinkButton>
            </UsefulToolsCardLinkButtonWrapper>
          </UsefulToolsCardWrapper>
        </Row>
      </Container>
    </UsefulToolsWrapper>
  )
}

export default UsefulTools
