import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import ArticleAboutModal from './article-about-modal'
import {
  ArticleWrapper,
  ArticleContainer,
  ArticlePersonWrapper,
  ArticlePersonPhotoColWrapper,
  ArticlePersonPhotoWrapper,
  ArticlePersonAboutColWrapper,
  ArticlePersonDescColWrapper,
  ArticlePersonDescWrapper,
} from '../elements'

const Article = (props) => {
  const data = useStaticQuery(graphql`
    query {
      Article1Image1: file(relativePath: { eq: "parent/article9/image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image2: file(relativePath: { eq: "parent/article9/image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticleWrapper>
      <ArticleContainer>
        <ArticlePersonDescWrapper>
          <Row className="marginTop90">
            <Col xs={12}>
              <p>
                <strong>
                  20 zł dla pięciolatki, 50 zł dla dziesięciolatka, 100 zł dla
                  nastolatka w wieku 15 lat – jak wynika z przeprowadzonych
                  przez BNP Paribas badań, takie przeciętne miesięczne
                  kieszonkowe dają rodzice<sup>1</sup>.
                </strong>
              </p>
            </Col>

            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image1.childImageSharp.fluid}
                className="articleImage"
                alt="Jak ustalić wysokość kieszonkowego?"
              />
            </Col>
            <Col
              xs={12}
              lg={6}
              className="ArticlePersonDescWrapperFlexVC marginTop30"
            >
              <p>
                Oczywiście wysokość kieszonkowego powinna być dostosowana do
                budżetu rodzinnego, ale trzeba uważać na kwoty bardzo niskie.
              </p>
              <p>
                <i>
                  Kieszonkowe przestaje spełniać swoją rolę, jeśli jest na tyle
                  niskie, że nie daje dziecku pola manewru, czyli możliwości
                  zebrania pieniędzy na realizację jakiegoś małego marzenia lubi
                  przeznaczenia na bieżące wydatki. Jeśli kieszonkowe jest zbyt
                  niskie, nie jest wtedy cenne i nie powoduje chęci planowania
                  wydatków. A przecież właśnie temu powinno służyć. Oczywiście
                  nie można też przesadzić w drugą stronę – kieszonkowe nie może
                  być zbyt wysokie i demoralizujące
                </i>{' '}
                – zaznacza dziennikarz ekonomiczny i bloger Maciej Samcik,
                ekspert Kapituły programu Misja Kieszonkowe.
              </p>
              <p>
                Zdaniem eksperta kieszonkowe powinno wynosić tyle, żeby dziecko,
                odkładając połowę tej kwoty w ciągu roku, mogło zrealizować
                jakieś swoje małe marzenie.
              </p>
            </Col>
            <Col xs={12} className="marginTop30">
              <p>
                W zależności od wieku dziecka możesz wypłacać kieszonkowe w
                formie gotówkowej lub bezgotówkowej. Jak radzą eksperci
                Kapituły, gotówka jest wskazana dla młodszych dzieci, przelewy
                na własne konto dziecka rekomendowane są natomiast dla dzieci
                znających nominały oraz potrafiących dobrze czytać i liczyć.
                <br />
                Jak zauważa Aleksander Naganowski, dyrektor ds. rozwoju
                rozwiązań cyfrowych w polskim oddziale Mastercard Europe, dużą
                zaletą gotówki jest to, że jest namacalnym przedmiotem, który
                można dotknąć, obejrzeć, przeliczyć i sprawdzić, jak zmienia się
                jego wartość podczas płacenia w sklepie. Kieszonkowe w formie
                gotówkowej trudniej jest jednak kontrolować, co niesie za sobą
                większe ryzyko nadużyć, kradzieży czy zguby.
              </p>
            </Col>
            <Col xs={12} lg={6} className="ArticlePersonDescWrapperFlexVC">
              <p>
                <i>
                  Jeśli chodzi natomiast o kieszonkowe w formie bezgotówkowej,
                  to jego największą zaletą jest możliwość sprawdzania historii
                  transakcji oraz efektywniejsze kontrolowanie budżetu. Pozwala
                  to wyciągać wnioski na przyszłość i świadomie uczyć się
                  finansów. Nastolatkowie, którzy dysponują większymi kwotami,
                  mogą docenić posiadanie karty jako bezpieczniejszej i
                  wygodniejszej formy płatności. Pokrywa się to również z ich
                  oczekiwaniami, bo jak pokazuje badanie BNP Paribas, 57%
                  respondentów w wieku 17-18 lat woli otrzymywać kieszonkowe w
                  formie bezgotówkowej
                </i>{' '}
                – podkreśla Aleksander Naganowski.
              </p>
              <p>
                Monika Sajkowska, prezeska Fundacji Dajemy Dzieciom Siłę, zwraca
                uwagę na sytuację rodziców gorzej sytuowanych, których frustruje
                i martwi brak możliwości wyposażenia dziecka w kieszonkowe
                dorównujące kwotom, którymi dysponują ich rówieśnicy. Obawy
                rodziców są poniekąd zasadne – zdaniem 42% nauczycieli i
                nauczycielek sytuacja materialna uczniów jest często lub bardzo
                często przyczyną dyskryminacji w szkole (RPO, 2021). Co w takiej
                sytuacji?
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image2.childImageSharp.fluid}
                className="articleImage"
                alt="Jak ustalić wysokość kieszonkowego?"
              />
            </Col>
            <Col xs={12} className="marginTop30">
              <p>
                <i>
                  Uniwersalną prawdą, którą warto przekazywać dziecku zawsze,
                  nie tylko w kryzysowych sytuacjach ostracyzmu, jest to, że,
                  wartość człowieka nie zależy od posiadanych rzeczy czy
                  pieniędzy, tylko od tego, kim jest. Docenianie dziecka,
                  dostrzeganie jego mocnych stron, ale też codzienny przekaz, że
                  jest wrażliwe, mądre, pomocne, będą budowały jego poczucie
                  wartości. Warto zapytać dziecko, dlaczego ważni są dla niego
                  rodzice, dziadkowie, przyjaciele. Czy powodem są pieniądze? Na
                  pewno nie!
                </i>{' '}
                – tłumaczy Monika Sajkowska.
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <strong>
                  Jeśli chcesz sprawdzić, jakie kieszonkowe dają polscy rodzice
                  w zależności od wieku dziecka, zajrzyj na{' '}
                  <a href="/misjasamodzielnosc/strefa-rodzica/kieszonkowe/">
                    https://www.bnpparibas.pl/misjasamodzielnosc/strefa-rodzica/kieszonkowe/
                  </a>
                  .
                </strong>
              </p>
            </Col>
            <Col xs={12} className="marginTop30">
              <p>Materiał przygotowany we współpracy z członkami Kapituły.</p>
            </Col>
            <Col xs={12} className="marginTop30 small">
              <p>
                <sup>1</sup> BNP Paribas, Badanie postaw rodziców wobec
                kieszonkowego. Raport z badania jakościowo-ilościowego, 2022.
              </p>
            </Col>
          </Row>
        </ArticlePersonDescWrapper>
      </ArticleContainer>
    </ArticleWrapper>
  )
}

export default Article
