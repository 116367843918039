import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ArticleHeader from '../../components/parent/article-header'
import Article9 from '../../components/parent/article9'
import UsefulTools from '../../components/parent/useful-tools'

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/article9/article-header9.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/gry.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Jak ustalić wysokość kieszonkowego?"
        description="Dajesz swojemu dziecku kieszonkowe, ale zastanawiasz się, czy wybrana kwota jest odpowiednia? Chcesz, by była dopasowana do domowego budżetu, ale zależy Ci też, aby Twoje dziecko nie odstawało od rówieśników i mogło czuć się bezpiecznie? Sprawdź, jaką przeciętną kwotę kieszonkowego dają polscy rodzice."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        date="12.04.2021"
        title={<>Jak ustalić wysokość kieszonkowego?</>}
        desc={
          <p>
            Dajesz swojemu dziecku kieszonkowe, ale zastanawiasz{'\u00A0'}się,
            <br />
            czy wybrana kwota jest odpowiednia? Chcesz, by{'\u00A0'}była
            <br />
            dopasowana do domowego budżetu, ale zależy Ci{'\u00A0'}też,
            <br />
            aby Twoje dziecko nie odstawało od rówieśników i{'\u00A0'}mogło
            <br />
            czuć się bezpiecznie? Sprawdź, jaką przeciętną kwotę
            <br />
            kieszonkowego dają polscy rodzice.
          </p>
        }
        linkPrev="/strefa-rodzica/baza-wiedzy"
        linkNext="/strefa-rodzica/baza-wiedzy#narzedzia"
      />
      <Article9 />
      <UsefulTools
        items={[
          {
            title: 'Krzyżówki',
            subtitle: 'Krzyżówka 2',
            image: data.Tool2,
            link: '/parent/Krzyzowka%202.pdf',
          },
          {
            title: 'Gry',
            subtitle: 'Budżet Samodzielniaka / Plansza i instrukcja',
            image: data.Tool1,
            link: '/parent/Budzet_Samodzielniaka_plansza_instrukcja.pdf',
          },
          {
            title: 'Gry',
            subtitle: 'Bankosłówka',
            image: data.Tool1,
            link: '/parent/Bankoslowka.pdf',
          },
        ]}
      />
    </Layout>
  )
}

export default IndexPage
