import React from 'react'
import { Link } from 'gatsby'
import { Link as LinkScroll } from 'react-scroll'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { NonceImg } from '../img'

const ArticleHeader2Wrapper = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
`

const ArticleHeaderImageWrapper = styled.div`
  position: relative;
`

const ArticleHeaderInnerWrapper = styled(Row)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media (max-width: 767px) {
    position: static;
  }
`

const ArticleHeaderColWrapper = styled(Col)`
  color: ${(props) => props.theme.colorWhite};
  padding-top: 20px;
  padding-left: 45px;
  @media (max-width: 767px) {
    color: ${(props) => props.theme.colorBlack};
  }
  @media (max-width: 575px) {
    padding-left: 15px;
  }
`

const ArticleHeader2ColWrapper = styled(Col)`
  color: ${(props) =>
    props.color ? props.color : props.theme.colorGreenLight};
  left: 65px;
  max-width: 220px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 767px) {
    left: 0;
  }

  @media (max-width: 575px) {
  }
`

const ArticleHeaderDate = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: -10px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 12px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 10px;
  }
`

const ArticleHeaderTitle = styled.h1`
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.15;
  letter-spacing: 1px;
  margin-bottom: 45px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 32px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 25px;
  }
`

const ArticleHeader2Title = styled.h1`
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.15;
  letter-spacing: 1px;
  margin-bottom: 45px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 32px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
  }

  @media (max-width: 590px) {
    font-size: 20px;
  }
`

const ArticleHeaderDescWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 10px;
  }
`

const ArticleHeaderPaginateWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 5px;
`

const ArticleHeaderTopPaginateWrapper = styled(ArticleHeaderPaginateWrapper)``

const ArticleHeaderBottomPaginateWrapper = styled(ArticleHeaderPaginateWrapper)`
  text-align: right;
`

const ArticleHeaderTopPaginateLink = styled(Link)`
  position: relative;
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  letter-spacing: 0.7px;
  transition: all 0.2s ease;
  cursor: pointer;
  ${(props) =>
    props.prev &&
    `
      padding-left: 25px;
  `}
  ${(props) =>
    props.next &&
    `
    padding-right: 25px;
  `}
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorGreenLight};
    text-decoration: none;
    &:before {
      ${(props) =>
    props.prev &&
    `
      left: 5px;
    `}
      ${(props) =>
    props.next &&
    `
      right: 5px;
    `}
    }
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    transition: all 0.2s ease;

    ${(props) =>
    props.prev &&
    `
      left: 10px;
      border-right: 6px solid ${props.theme.colorGreenLight};
    `}
    ${(props) =>
    props.next &&
    `
      right: 10px;
      border-left: 6px solid ${props.theme.colorGreenLight};
    `}
  }
`

const ArticleHeader = (props) => {
  const { image, date, title, desc, linkPrev } = props

  return (
    <ArticleHeader2Wrapper>
      <Container>
        <ArticleHeaderTopPaginateWrapper>
          <ArticleHeaderTopPaginateLink
            to={linkPrev}
            prev
            title="powrót do strefy rodzica"
          >
            powrót do{'\u00A0'}strefy rodzica
          </ArticleHeaderTopPaginateLink>
        </ArticleHeaderTopPaginateWrapper>
        <ArticleHeaderImageWrapper>
          <NonceImg fluid={image} className="articleHeaderImage" alt={title} />
          <ArticleHeaderInnerWrapper>
            <ArticleHeaderColWrapper>
              <ArticleHeaderDate>{date}</ArticleHeaderDate>
              <ArticleHeaderTitle>{title}</ArticleHeaderTitle>
              <ArticleHeaderDescWrapper>{desc}</ArticleHeaderDescWrapper>
            </ArticleHeaderColWrapper>
          </ArticleHeaderInnerWrapper>
        </ArticleHeaderImageWrapper>
        <ArticleHeaderBottomPaginateWrapper>
          <ArticleHeaderTopPaginateLink
            as={LinkScroll}
            to="usefulTools"
            smooth
            offset={-100}
            next
            title="zobacz narzędzia do artykułu"
          >
            zobacz narzędzia do{'\u00A0'}artykułu
          </ArticleHeaderTopPaginateLink>
        </ArticleHeaderBottomPaginateWrapper>
      </Container>
    </ArticleHeader2Wrapper>
  )
}

export const ArticleHeader2 = (props) => {
  const { image, title, linkPrev, color } = props

  return (
    <ArticleHeader2Wrapper>
      <Container>
        <ArticleHeaderTopPaginateWrapper>
          <ArticleHeaderTopPaginateLink
            to={linkPrev}
            prev
            title="powrót do strefy rodzica"
          >
            powrót do{'\u00A0'}strefy rodzica
          </ArticleHeaderTopPaginateLink>
        </ArticleHeaderTopPaginateWrapper>
        <ArticleHeaderImageWrapper>
          <NonceImg fluid={image} className="articleHeaderImage" alt={title} />
          <ArticleHeaderInnerWrapper>
            <ArticleHeader2ColWrapper color={color}>
              <ArticleHeader2Title>{title}</ArticleHeader2Title>
            </ArticleHeader2ColWrapper>
          </ArticleHeaderInnerWrapper>
        </ArticleHeaderImageWrapper>
      </Container>
    </ArticleHeader2Wrapper>
  )
}

export default ArticleHeader
